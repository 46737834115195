<script setup>
import { usePage } from "@inertiajs/vue3";
import Button from "primevue/button";
import { computed, onMounted, ref } from "vue";

import { resetMainView } from "@/Composables/ResetView";

const props = defineProps({
    message: { type: String, default: null },
    style: { type: String, default: null },
    show: { type: Boolean, default: null },
    dismissible: { type: Boolean, default: null },
});

const page = usePage();
const isShowing = ref(true);

const show = computed(() => {
    if (props.show !== null) {
        return props.show;
    }

    return isShowing.value;
});

const style = computed(() => {
    if (props.style !== null) {
        return props.style;
    }

    if (page.props.jetstream.flash?.bannerStyle) {
        return page.props.jetstream.flash?.bannerStyle;
    }

    return "success";
});

const message = computed(() => {
    if (props.message !== null) {
        return props.message;
    }

    if (page.props.jetstream.flash?.banner) {
        return page.props.jetstream.flash?.banner;
    }

    return "";
});

const dismissible = computed(() => {
    if (props.dismissible !== null) {
        return props.dismissible;
    }

    return true;
});

onMounted(() => {
    resetMainView();
});
</script>

<template>
    <div
        v-if="!!(show && message)"
        :class="{
            success: style === 'success',
            danger: style === 'danger',
            warning: style === 'warning',
            info: style === 'info',
        }"
    >
        <div class="container mx-auto px-4 py-3 sm:px-12">
            <div class="flex lg:items-center lg:justify-between">
                <div class="flex lg:items-center">
                    <span
                        class="mt-1 flex h-8 w-8 items-center justify-center rounded-full p-4"
                        :class="{
                            'bg-green-800': style === 'success',
                            'bg-red-800': style === 'danger',
                            'bg-orange-600': style === 'warning',
                            'bg-blue-400': style === 'info',
                        }"
                    >
                        <i v-if="style === 'success'" class="pi pi-check-circle text-white" />
                        <i v-if="style === 'danger'" class="pi pi-exclamation-circle text-white" />
                        <i v-if="style === 'info'" class="pi pi-info-circle text-white" />
                        <i v-if="style === 'warning'" class="pi pi-exclamation-circle text-white" />
                    </span>

                    <p class="ml-3 text-sm font-medium text-white">
                        {{ message }}
                    </p>
                </div>
                <div class="shrink-0 sm:ml-3">
                    <Button
                        v-if="dismissible"
                        v-ripple
                        type="button"
                        class="-mr-1 flex rounded-md p-2 transition focus:outline-none sm:-mr-2"
                        severity="none"
                        :class="{
                            'hover:bg-green-800 focus:bg-green-800': style === 'success',
                            'hover:bg-red-800 focus:bg-red-800': style === 'danger',
                            'hover:bg-orange-600 focus:bg-orange-600': style === 'warning',
                            'hover:bg-blue-400 focus:bg-blue-400': style === 'info',
                        }"
                        aria-label="Dismiss"
                        @click.prevent="isShowing = false"
                    >
                        <svg
                            class="h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.success {
    @apply border-green-800 bg-gradient-to-r from-green-600 to-green-500;
}

.info {
    @apply border-blue-800 bg-gradient-to-r from-blue-500 to-blue-700;
}

.warning {
    @apply border-orange-800 bg-gradient-to-r from-orange-500 to-orange-700;
}

.danger {
    @apply border-red-800 bg-gradient-to-r from-red-700 to-red-500;
}
</style>
