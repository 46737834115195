<script setup>
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import { ref } from "vue";

const searchQuery = ref("");
</script>

<template>
    <IconField>
        <InputText v-model="searchQuery" placeholder="Search patients" class="w-64" />
        <InputIcon class="pi pi-search" />
    </IconField>
</template>
