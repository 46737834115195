<script setup>
import { usePage } from "@inertiajs/vue3";
import { computed, nextTick, ref, watch } from "vue";

import Banner from "@/Components/Banner.vue";
import InitSW from "@/Composables/InitSW.js";

import Sidebar from "./SuperSidebar.vue";

defineProps({
    title: String,
});

const animate = ref(true);
const isLeaving = ref(false);

const currentRoute = computed(() => usePage().props.current_route);
watch(currentRoute, async () => {
    isLeaving.value = true;
    animate.value = false;

    // Wait for leave animation to complete
    await new Promise((resolve) => setTimeout(resolve, 300));

    isLeaving.value = false;
    nextTick(() => {
        animate.value = true;
    });
});

InitSW();
Echo.private(`Super.User.` + usePage().props.auth.user.id).listen(".test.event", (event) => {
    alert(event.message);
});
</script>

<template>
    <div class="relative isolate flex min-h-svh w-full max-lg:flex-col bg-cerbo-secondary">
        <Head :title="title" />

        <Sidebar />

        <main class="flex flex-1 flex-col pb-2 lg:min-w-0 lg:pl-64 lg:pr-2 lg:pt-2">
            <Banner />

            <div
                class="grow p-6 lg:rounded-lg lg:p-10 lg:shadow-sm bg-cerbo-secondary border border-primary shadow-xl"
            >
                <transition name="slide-fade" mode="out-in" appear>
                    <div
                        v-if="animate"
                        :class="{ 'is-leaving': isLeaving }"
                        class="mx-auto container"
                    >
                        <main>
                            <!-- Content -->
                            <div class="h-[calc(100%_-_84px)]">
                                <slot />
                            </div>
                        </main>
                    </div>
                </transition>
            </div>
        </main>
    </div>
</template>

<style>
/*
  Slide Fade Transition
*/
.slide-right-enter-active,
.slide-left-enter-active,
.slide-bottom-enter-active,
.slide-top-enter-active {
    transition: all 0.3s ease-out;
}

.slide-right-leave-active,
.slide-left-leave-active,
.slide-bottom-leave-active,
.slide-top-enter-active {
    transition: all 0.3s;
}

.slide-right-enter-from,
.slide-right-leave-to {
    transform: translateX(50%);
    opacity: 0;
}

.slide-left-enter-from,
.slide-left-leave-to {
    transform: translateX(-50%);
    opacity: 0;
}

.slide-bottom-enter-from,
.slide-bottom-leave-to {
    transform: translateY(25%);
    opacity: 0;
}

.slide-top-enter-from,
.slide-top-leave-to {
    transform: translateY(-50%);
    opacity: 0;
}

/*
  Fade Transition
*/
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/*
  Slide Fade Combined Transition
*/
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-fade-enter-from {
    transform: translateY(10px);
    opacity: 0;
}

.slide-fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
}

/* Add appear transition classes */
.slide-fade-appear-active {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-fade-appear-from {
    transform: translateY(10px);
    opacity: 0;
}

/* Optional: Add class for leaving state */
.is-leaving {
    pointer-events: none;
}
</style>
