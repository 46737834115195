import "./bootstrap";
import "../assets/styles/tailwind.css";
import "primeicons/primeicons.css";

import { createInertiaApp, Head, Link } from "@inertiajs/vue3";
import Vapor from "laravel-vapor";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import Ripple from "primevue/ripple";
import Tooltip from "primevue/tooltip";
import { createApp, h } from "vue";
import { ZiggyVue } from "ziggy-js";

import SimpleHeader from "@/Components/SimpleHeader.vue";
import GuestLayout from "@/Layouts/GuestLayout.vue";
import SuperAppLayout from "@/Layouts/SuperAdmin/SuperAppLayout.vue";
import TenantAppLayout from "@/Layouts/Tenant/AppLayout.vue";

// Store layouts globally
const layouts = {
    SuperAppLayout,
    GuestLayout,
    TenantAppLayout,
};

const appName = import.meta.env.VITE_APP_NAME || "Laravel";
Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);
window.Vapor = Vapor;

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name, pages, props) => {
        const pageModules = import.meta.glob("./Pages/**/*.vue");
        const page = (await pageModules[`./Pages/${name}.vue`]()).default;

        // If defineOptions specifies a layout name, use that layout
        if (page.layout) {
            page.layout = typeof page.layout === "string" ? layouts[page.layout] : page.layout;
        } else if (name.startsWith("SuperAdmin/")) {
            page.layout = layouts.SuperAppLayout;
        } else if (name.startsWith("Auth/")) {
            page.layout = layouts.GuestLayout;
        } else {
            page.layout = layouts.TenantAppLayout;
        }

        return page;
    },
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(PrimeVue, {
                theme: "none",
                ripple: true,
            })
            .use(ConfirmationService)
            .component("Link", Link)
            .component("Head", Head)
            .component("SimpleHeader", SimpleHeader)
            .directive("ripple", Ripple)
            .directive("tooltip", Tooltip)
            .mixin({ methods: { asset: window.Vapor.asset } })
            .mount(el);
    },
    progress: {
        delay: 500,
        color: "#4B5563",
        showSpinner: true,
    },
});
