<script setup>
import { Link } from "@inertiajs/vue3";

import ApplicationMark from "@/Components/ApplicationMark.vue";

import ActionIcons from "./ActionIcons.vue";
import NavigationLinks from "./NavigationLinks.vue";
import SearchBar from "./SearchBar.vue";

const { showingNavigationDropdown } = defineProps({
    showingNavigationDropdown: Boolean,
});

const emit = defineEmits(["update:showingNavigationDropdown"]);
</script>

<template>
    <nav class="bg-cerbo-primary border-b border-primary">
        <div class="mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
                <!-- Left side: Logo and Navigation -->
                <div class="flex">
                    <!-- Logo -->
                    <div class="shrink-0 flex items-center">
                        <Link v-ripple :href="route('dashboard')" class="p-ripple">
                            <ApplicationMark class="block h-9 w-auto" />
                        </Link>
                    </div>

                    <!-- Desktop Navigation -->
                    <NavigationLinks :is-mobile="false" />
                </div>

                <!-- Right side: Search and Actions -->
                <div class="flex items-center">
                    <!-- Search Bar - Hidden on mobile -->
                    <div class="hidden md:block me-4">
                        <SearchBar />
                    </div>

                    <!-- Action Icons - Always visible -->
                    <ActionIcons />

                    <!-- Hamburger -->
                    <div class="lg:hidden ms-4 flex items-center">
                        <button
                            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                            @click="
                                emit('update:showingNavigationDropdown', !showingNavigationDropdown)
                            "
                        >
                            <svg
                                class="h-6 w-6"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    :class="{
                                        hidden: showingNavigationDropdown,
                                        'inline-flex': !showingNavigationDropdown,
                                    }"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                                <path
                                    :class="{
                                        hidden: !showingNavigationDropdown,
                                        'inline-flex': showingNavigationDropdown,
                                    }"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile Navigation Menu -->
        <div
            :class="{
                block: showingNavigationDropdown,
                hidden: !showingNavigationDropdown,
            }"
            class="lg:hidden"
        >
            <NavigationLinks :is-mobile="true" />
        </div>
    </nav>
</template>
