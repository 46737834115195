<script setup></script>
<template>
    <svg
        width="142"
        height="36"
        viewBox="0 0 142 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_668_34746)">
            <path
                d="M59.1276 23.7595L60.6102 24.4699C58.7638 26.901 55.9593 28.2897 52.905 28.2897C47.5595 28.2897 43.2106 23.9408 43.2106 18.5953C43.2106 13.2485 47.5595 8.90082 52.905 8.90082C55.9593 8.90082 58.7638 10.2896 60.6102 12.7206L59.1276 13.4311C57.5848 11.5687 55.3361 10.5104 52.905 10.5104C48.4467 10.5104 44.8204 14.1365 44.8204 18.5953C44.8204 23.054 48.4467 26.6802 52.905 26.6802C55.3351 26.6802 57.5833 25.6219 59.1276 23.7595Z"
                class="fill-black dark:fill-white"
            />
            <path
                d="M131.61 8.89941C126.264 8.89941 121.913 13.2483 121.913 18.5951C121.913 23.9418 126.264 28.2908 131.61 28.2908C136.956 28.2908 141.306 23.9418 141.306 18.5951C141.306 13.2483 136.956 8.89941 131.61 8.89941ZM131.61 26.68C127.152 26.68 123.525 23.0538 123.525 18.5951C123.525 14.1364 127.152 10.5102 131.61 10.5102C136.066 10.5102 139.694 14.1364 139.694 18.5951C139.694 23.0538 136.066 26.68 131.61 26.68Z"
                class="fill-black dark:fill-white"
            />
            <path
                d="M109.394 8.89943C106.021 8.89943 103.047 10.6336 101.309 13.2546V1.04767H99.7V27.9184H101.309V23.9345C103.047 26.5555 106.022 28.2896 109.394 28.2896C114.74 28.2896 119.09 23.9407 119.09 18.5939C119.09 13.2471 114.74 8.89943 109.394 8.89943ZM109.394 26.68C104.938 26.68 101.309 23.0539 101.309 18.5952C101.309 14.1364 104.938 10.5103 109.394 10.5103C113.853 10.5103 117.479 14.1364 117.479 18.5952C117.479 23.0539 113.853 26.68 109.394 26.68Z"
                class="fill-black dark:fill-white"
            />
            <path
                d="M94.6635 8.96094V10.5853C92.6905 10.8517 90.9439 11.8298 89.6868 13.2543C88.4313 14.6801 87.6702 16.5488 87.6702 18.5937V27.917H86.0585V9.27047H87.6702V13.2667C89.2156 10.9282 91.742 9.29145 94.6635 8.96094Z"
                class="fill-black dark:fill-white"
            />
            <path
                d="M79.3343 23.7593C77.793 25.6218 75.5382 26.68 73.1132 26.68C71.0632 26.68 69.1899 25.9141 67.7601 24.651L79.1691 13.2385L80.3041 12.1037C79.9464 11.7017 79.5552 11.3317 79.1357 11.0011C77.4499 9.64561 75.3446 8.89941 73.1132 8.89941C67.7677 8.89941 63.4112 13.2483 63.4112 18.5951C63.4112 23.9418 67.7667 28.2908 73.1132 28.2908C76.166 28.2908 78.9705 26.902 80.8184 24.4698L79.3343 23.7593ZM65.0209 18.5951C65.0209 14.1364 68.6473 10.5102 73.1122 10.5102C74.9043 10.5102 76.6002 11.0886 77.9901 12.1407L66.6636 23.467C65.635 22.1128 65.0209 20.4193 65.0209 18.5951Z"
                class="fill-black dark:fill-white"
            />
            <path
                d="M20.607 29.9155C21.2228 30.5631 21.6008 31.4364 21.6008 32.4002C21.6008 34.3896 19.9883 36 18.0009 36C16.0125 36 14.401 34.3896 14.401 32.4002C14.401 31.4364 14.78 30.5631 15.3947 29.9155C15.4146 29.8956 15.4345 29.8757 15.4544 29.8558L15.4942 29.816C15.8971 29.4052 16.2372 28.9556 16.5108 28.4772C17.2031 27.2716 17.4826 25.895 17.3494 24.5501C17.2608 23.637 16.9803 22.7408 16.5098 21.9232C16.2263 21.4308 15.8752 20.9673 15.4544 20.5455C14.8039 19.893 14.4 18.9938 14.4 18.0001C14.4 16.0117 16.0125 14.4003 17.9999 14.4003C18.9638 14.4003 19.8371 14.7793 20.4847 15.394C20.5046 15.4148 20.5245 15.4358 20.5444 15.4557C20.5643 15.4755 20.5841 15.4954 20.6061 15.5153C21.2218 16.1629 21.5998 17.0362 21.5998 18.0001C21.5998 18.9788 21.2098 19.8651 20.5752 20.5156C20.5643 20.5266 20.5553 20.5355 20.5444 20.5465C20.5334 20.5574 20.5245 20.5664 20.5145 20.5773C20.1067 20.9901 19.7645 21.4427 19.489 21.9232C19.0185 22.7418 18.738 23.639 18.6494 24.5511C18.5162 25.895 18.7957 27.2726 19.489 28.4782C19.7576 28.9477 20.0888 29.3913 20.4847 29.7951C20.5046 29.816 20.5245 29.8369 20.5444 29.8568C20.5662 29.8757 20.5861 29.8956 20.607 29.9155Z"
                fill="#EE7326"
            />
            <path
                d="M36 17.9999C36 19.9884 34.3876 21.5998 32.4001 21.5998C31.4064 21.5998 30.5061 21.1969 29.8546 20.5454C29.4338 20.1246 28.9703 19.7725 28.4769 19.49C27.2713 18.7967 25.8946 18.5172 24.5488 18.6505C23.6983 18.7331 22.8598 18.9827 22.0889 19.3985C22.239 18.9598 22.3196 18.4894 22.3196 17.9999C22.3196 17.5115 22.2381 17.0411 22.0898 16.6034C22.8627 17.0182 23.6983 17.2669 24.5488 17.3494C25.8946 17.4827 27.2723 17.2032 28.4779 16.5099C28.9474 16.2404 29.3891 15.9081 29.7939 15.5142L29.8845 15.4237C29.8884 15.4197 29.8914 15.4167 29.8934 15.4128C30.542 14.7851 31.4253 14.3992 32.3991 14.3992C34.3885 14.4002 36 16.0116 36 17.9999Z"
                fill="#FBBA00"
            />
            <path
                d="M3.59989 21.5998C5.58805 21.5998 7.19979 19.9882 7.19979 18.0001C7.19979 16.012 5.58805 14.4003 3.59989 14.4003C1.61172 14.4003 0 16.012 0 18.0001C0 19.9882 1.61172 21.5998 3.59989 21.5998Z"
                fill="#64B445"
            />
            <path
                d="M13.4069 22.7159C14.0226 23.3634 14.4006 24.2367 14.4006 25.2006C14.4006 27.19 12.7882 28.8004 10.8007 28.8004C8.81128 28.8004 7.20085 27.19 7.20085 25.2006C7.20085 24.2367 7.57982 23.3634 8.19457 22.7159C8.2244 22.685 8.25423 22.6542 8.28508 22.6253C8.69192 22.2125 9.03411 21.76 9.30965 21.2785C9.78016 20.4609 10.0597 19.5637 10.1492 18.6515C10.2835 17.3077 10.0029 15.928 9.30965 14.7225C9.02712 14.2311 8.675 13.7676 8.25423 13.3468C7.60368 12.6943 7.19983 11.7951 7.19983 10.8014C7.19983 8.81302 8.8103 7.2016 10.7997 7.2016C11.7636 7.2016 12.637 7.5806 13.2845 8.1953C13.3044 8.2152 13.3243 8.2351 13.3442 8.255C13.3551 8.26595 13.3641 8.2749 13.375 8.28581C14.0097 8.93536 14.3996 9.82265 14.3996 10.8014C14.3996 11.7802 14.0097 12.6664 13.375 13.317C13.3641 13.3279 13.3551 13.3369 13.3442 13.3478C13.3333 13.3588 13.3243 13.3677 13.3144 13.3787C12.9065 13.7915 12.5644 14.244 12.2888 14.7245C11.5955 15.9301 11.315 17.3097 11.4493 18.6535C11.5388 19.5667 11.8183 20.4638 12.2888 21.2805C12.5574 21.75 12.8886 22.1916 13.2836 22.5965C13.3035 22.6174 13.3233 22.6383 13.3432 22.6582C13.3651 22.6751 13.385 22.6949 13.4069 22.7159Z"
                fill="#D70073"
            />
            <path
                d="M25.1997 28.8003C27.1878 28.8003 28.7996 27.1886 28.7996 25.2005C28.7996 23.2124 27.1878 21.6007 25.1997 21.6007C23.2115 21.6007 21.5998 23.2124 21.5998 25.2005C21.5998 27.1886 23.2115 28.8003 25.1997 28.8003Z"
                fill="#00B1B2"
            />
            <path
                d="M17.9993 7.19958C19.9875 7.19958 21.5992 5.58792 21.5992 3.59979C21.5992 1.61168 19.9875 0 17.9993 0C16.0112 0 14.3994 1.61168 14.3994 3.59979C14.3994 5.58792 16.0112 7.19958 17.9993 7.19958Z"
                fill="#9F358B"
            />
            <path
                d="M28.8004 10.7994C28.8004 12.7877 27.188 14.3991 25.2006 14.3991C24.2068 14.3991 23.3066 13.9963 22.6551 13.3448C22.2343 12.924 21.7708 12.5719 21.2774 12.2894C20.4597 11.8189 19.5625 11.5394 18.6503 11.4498C17.3671 11.3226 16.0551 11.5712 14.8903 12.1979C15.0405 11.7592 15.1211 11.2887 15.1211 10.7994C15.1211 10.3099 15.0395 9.83945 14.8903 9.40079C16.0551 10.0265 17.3682 10.2761 18.6503 10.1488C19.5635 10.0593 20.4607 9.77979 21.2774 9.3093C21.7708 9.0258 22.2333 8.67466 22.6551 8.2539C23.3076 7.60337 24.2068 7.19952 25.2006 7.19952C27.188 7.19952 28.8004 8.81192 28.8004 10.7994Z"
                fill="#008ECF"
            />
        </g>
        <defs>
            <clipPath id="clip0_668_34746">
                <rect width="141.517" height="36" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
