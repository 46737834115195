<script setup>
import { Head } from "@inertiajs/vue3";
import { ref } from "vue";

import Banner from "@/Components/Banner.vue";

import MainNavigation from "./LayoutComponents/MainNavigation.vue";

defineProps({
    title: String,
});

const showingNavigationDropdown = ref(false);
</script>

<template>
    <div id="main-content" class="container-fluid">
        <Head :title="title" />
        <!-- Notifications -->
        <Banner />

        <div class="min-h-screen bg-cerbo-secondary">
            <!-- The Top Navigation containing the logo, search bar, and action icons -->
            <MainNavigation v-model:showing-navigation-dropdown="showingNavigationDropdown" />

            <!-- Page Heading -->
            <header v-if="$slots.header" class="bg-white dark:bg-gray-800 shadow">
                <div class="mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <slot name="header" />
                </div>
            </header>

            <!-- Page Content -->
            <main class="sm:px-6 lg:px-8">
                <slot />
            </main>
        </div>
    </div>
</template>
