// eslint-disable-next-line require-jsdoc
export default function init() {
    if (!("serviceWorker" in navigator)) {
        console.warn("Service Workers aren't supported");
        return;
    }

    if (!("PushManager" in window)) {
        console.warn("Push Manager isn't supported");
        return;
    }

    // register the service worker
    navigator.serviceWorker
        .register("/sw.js")
        .then(() => {
            if (!IS_PROD) {
                console.log("Service Worker installed!");
            }
            initPush();
        })
        .catch((err) => {
            console.log(err);
        });
}

const initPush = () => {
    if (!navigator.serviceWorker.ready) {
        console.warn("SW not ready");
        return;
    }

    new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    }).then((permissionResult) => {
        if (permissionResult !== "granted") {
            throw new Error("We weren't granted permission.");
        }
        subscribeUser();
    });
};

const subscribeUser = () => {
    navigator.serviceWorker.ready
        .then((registration) => {
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(import.meta.env.VITE_VAPID_PUBLIC_KEY),
            };

            if (!IS_PROD) {
                console.log(subscribeOptions);
            }

            return registration.pushManager.subscribe(subscribeOptions);
        })
        .then((pushSubscription) => {
            if (!IS_PROD) {
                console.log("Received PushSubscription: ", JSON.stringify(pushSubscription));
            }
            storePushSubscription(pushSubscription);
        });
};

const storePushSubscription = (pushSubscription) => {
    axios
        .post("/push-subscription", pushSubscription)
        .then(() => {
            if (!IS_PROD) {
                console.log("Successfully saved push notification subscription");
            }
        })
        .catch((err) => {
            console.error("Failed to save push notification subscription", err);
        });
};

const urlBase64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};
