<script setup>
import Popover from "primevue/popover";
import { computed, ref } from "vue";

import DropdownLink from "@/Components/DropdownLink.vue";

const popoverRef = ref();

const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
    minimal: {
        type: Boolean,
        default: false,
    },
    onToggle: {
        type: Function,
        default: () => {},
    },
    onLogout: {
        type: Function,
        default: () => {},
    },
});

const handleToggle = (event) => {
    popoverRef.value?.toggle(event);
    props.onToggle(event);
};

const hasProfilePhoto = computed(() => {
    return props.user?.jetstream?.managesProfilePhotos || false;
});
</script>

<template>
    <div class="relative">
        <!-- Profile Button -->
        <button
            type="button"
            :class="[
                'flex items-center gap-3 rounded-lg p-3 text-left hover:bg-zinc-50 dark:hover:bg-white/5',
                minimal ? '' : 'w-full',
            ]"
            @click="handleToggle($event)"
        >
            <!-- Avatar -->
            <div class="relative h-8 w-8 flex-none">
                <img
                    v-if="hasProfilePhoto"
                    class="h-full w-full rounded-full object-cover"
                    :src="user.profile_photo_url"
                    :alt="user.name"
                />
                <div
                    v-else
                    class="flex h-full w-full items-center justify-center rounded-full bg-primary/10"
                >
                    <span class="text-sm font-medium text-primary">
                        {{ user.name.charAt(0).toUpperCase() }}
                    </span>
                </div>
            </div>

            <!-- User Info - Only show if not minimal -->
            <div v-if="!minimal" class="min-w-0 flex-auto">
                <p class="text-sm font-semibold text-primary dark:text-white">
                    {{ user.name }}
                </p>
                <p class="truncate text-xs text-zinc-500 dark:text-zinc-400">
                    {{ user.email }}
                </p>
            </div>
        </button>

        <!-- Popover Menu -->
        <Popover
            ref="popoverRef"
            :pt="{
                root: { class: 'surface-ground dark:bg-cerbo-secondary' },
            }"
        >
            <div class="py-1 w-48">
                <div class="block px-4 py-2 text-xs text-zinc-500">Manage Account</div>

                <DropdownLink :href="route('super.profile.show')">Profile</DropdownLink>

                <div class="border-t border-zinc-200 dark:border-zinc-700" />

                <form @submit.prevent="onLogout">
                    <DropdownLink as="button">Log Out</DropdownLink>
                </form>
            </div>
        </Popover>
    </div>
</template>
