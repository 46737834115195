<script setup>
import { ref } from "vue";

import DarkModeButton from "@/Components/DarkModeButton.vue";

const themeButton = ref(null);

const toggleThemeButton = (event) => {
    if (event.target.id.includes("theme-toggle-wrapper")) {
        themeButton.value?.toggleDarkMode();
    }
};
</script>

<template>
    <div class="mt-auto flex flex-col gap-0.5">
        <span class="relative">
            <a
                href="#"
                class="flex w-full items-center gap-3 rounded-lg px-2 py-2.5 text-left text-base/6 font-medium text-primary"
            >
                <span class="truncate">Support</span>
            </a>
            <a
                href="#"
                class="flex w-full items-center gap-3 rounded-lg px-2 py-2.5 text-left text-base/6 font-medium text-primary"
            >
                <span class="truncate">Changelog</span>
            </a>
            <span
                id="theme-toggle-wrapper"
                class="cursor-pointer flex justify-between w-full items-center gap-3 rounded-lg px-2 py-2.5 text-left text-base/6 font-medium text-primary"
                @click="toggleThemeButton"
            >
                Theme
                <DarkModeButton ref="themeButton" />
            </span>
        </span>
    </div>
</template>
